<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button variant="primary" :to="{ name: 'distributor_create' }">
                <span class="text-nowrap">{{
                  $t("Create User")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="distributorData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
        :empty-filtered-text="$t('There are no records matching your request!')"
      >
        <template #cell(isconfirm)="data" v-if="false">
          <!--talebe göre gösterilebilir-->
          <p>{{ data.item.is_confirm ? $t("Yes") : $t("No") }}</p>
        </template>

        <template #cell(actions)="data">
          <template v-if="isMobile === true">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :to="{
                  name: 'distributorEdit',
                  params: { personID: data.item.person_id, companyBranchPersonRoleId: data.item.company_branch_person_role_id},
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.is_confirm"
                :variant="
                  data.item.is_active === '0' || data.item.is_active === false
                    ? 'success'
                    : 'danger'
                "
                @click="success(data.item.person_id, data.item.is_active)"
              >
                <feather-icon icon="ActivityIcon" />
                <span class="align-middle ml-50">
                  {{
                    data.item.is_active === "0" || data.item.is_active === false
                      ? $t("Activate")
                      : $t("Deactivate")
                  }}
                </span>
              </b-dropdown-item>

              <b-button
                size="sm"
                v-if="!data.item.is_confirm"
                variant="primary"
                @click="reSendInvite(data.item.company_branch_person_role_id, data.item.person_id)"
              >
                {{ $t("Re-Send Invite") }}
              </b-button>
            </b-dropdown>
          </template>

          <template v-if="isMobile === false">
            <b-button
              size="sm"
              class="mr-1"
              variant="primary"
              :to="{
                name: 'distributorEdit',
                params: { personID: data.item.person_id, companyBranchPersonRoleId: data.item.company_branch_person_role_id },
              }"
              >{{ $t("Edit") }}</b-button
            >
            <!--confirm olmayanın aktif pasif olmasına gerek yok-->
            <b-button
              size="sm"
              v-if="data.item.is_confirm"
              :variant="
                data.item.is_active === '0' || data.item.is_active === false
                  ? 'success'
                  : 'danger'
              "
              @click="success(data.item.person_id, data.item.is_active)"
              >{{
                data.item.is_active === "0" || data.item.is_active === false
                  ? $t("Activate")
                  : $t("Deactivate")
              }}</b-button
            >
            <b-button size="sm" v-if="!data.item.is_confirm" variant="primary" @click="reSendInvite(data.item.company_branch_person_role_id, data.item.person_id)">
              {{ $t("Re-Send Invite") }}
            </b-button>
          </template>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col> -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-left
              justify-content-right justify-content-sm-start
              radius
              0
            "
          >
            <b-form-select
              v-model="selected"
              :options="options"
              @change="changeFiltering"
              class="select-config"
            >
            </b-form-select>
          </b-col>
          <small class="mt-1" v-if="$i18n.locale === 'tr'"
            >içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
              currentPage * perPage
            }}
            arası ({{ currentPage }}.sayfa) gösteriliyor
          </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{totalRows}} records</small>
          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,

      filterOn: [],

      locale: this.$i18n.locale,

      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "full_name", label: this.$t("FULL NAME") },
        { key: "email", label: this.$t("E-mail") },
        //{ key: "isconfirm", label: "Onaylı mı"},
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],
      distributorData: [],
      selected: "tüm",
      options: [
        { text: this.$t("All Distributor Users"), value: "tüm" },
        { text: this.$t("Active Distributor Users"), value: "aktif" },
        { text: this.$t("Deactive Distributor Users"), value: "deaktif" },
      ],
      companyBranchID: "",
      distributor_person_list: [],
      company_branch_persons_data: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.distributorData
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    distributorData(val) {
      this.totalRows = val.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.distributorData.length;
  },

  methods: {
    //müşterilerşn aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
      if (arg === "tüm") {
        this.distributorData = this.distributorBaseData;
      } else if (arg === "aktif") {
        //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
        let data = [];
        this.distributorBaseData.map((x) => {
          if ((x.is_active === true || x.is_active === 1) && (x.is_confirm === true || x.is_confirm === '1')) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.distributorData = data;
      } else if (arg === "deaktif") {
        //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
        let data = [];
        this.distributorBaseData.map((x) => {
          if ((x.is_active === false || x.is_active === 0) && (x.is_confirm === true || x.is_confirm === '1')) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.distributorData = data;
      } else {
        //null gelebilir..
        this.distributorData = this.distributorBaseData;
      }
    },
    success(val, is_active) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change User Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (is_active === "0" || is_active === false) {
            this.$database.DistributorService.person_deactive(val, "1").then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Action failed!");
                } else {
                  this.$functions.Messages.success("User status changed");
                  this.distributorBaseData = [];
                  this.distributorData = [];
                  this.getDistributorList();
                }
              }
            );
          }
          if (is_active === "1" || is_active === true) {
            this.$database.DistributorService.person_deactive(val, "0").then(
              (res) => {
                if (!res.is_success) {
                  this.$functions.Messages.error("Action failed!");
                } else {
                  this.$functions.Messages.success("Action Successful");
                  this.distributorBaseData = [];
                  this.distributorData = [];
                  this.getDistributorList();
                }
              }
            );
          }
        }
      });
    },

    getDistributorList() {
      // this.$database.DistributorService.get_distributor_list(
      //   this.companyBranchID
      // ).then(res => {
      //   if (!res.is_success) return;
      //   if (!res.result) return;
      //   else {
      //     this.distributorBaseData = res.result.map(el => {
      //       const full_name = el.name + " " + el.last_name
      //       return{
      //         ...el,
      //         full_name:full_name
      //       }
      //     });
      //     this.distributorData = res.result.map(el => {
      //       const full_name = el.name + " " + el.last_name
      //       return{
      //         ...el,
      //         full_name:full_name
      //       }
      //     });
      //   }
      // })
      this.$database.DistributorService.get_distributor_person_list()
        .then(async (res) => {
          if (res.is_success === true || res.is_success === 1) {
            this.distributor_person_list = await res.result;
          }
        })
        .then((res) => {
          this.$database.DistributorService.get_person_and_company_branch_person_data(
            this.companyBranchID
          )
            .then(async (res) => {
              this.company_branch_persons_data = await res.result;
              // console.log(this.distributor_person_list);
              // console.log(this.company_branch_persons_data);
            })
            .then((res) => {
              this.company_branch_persons_data.map((el) => {
                this.distributor_person_list.map((x) => {
                  //person id leri eşleştir distributorData içerisine istedigim verileri pushla
                  if (
                    el.company_branch_person_id === x.company_branch_person_id
                  ) {
                    let full_name = el.name + " " + el.last_name;
                    this.distributorData.push({
                      is_confirm: x.is_confirm,
                      menu_role_group_id: x.menu_role_group_id,
                      company_branch_person_role_id:
                        x.company_branch_person_role_id,
                      full_name,
                      ...el,
                    });
                  }
                });
              });
            })
            .then((res) => {
              this.distributorBaseData = this.distributorData;
              // console.log(this.distributorBaseData[0]);
            });
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    reSendInvite(company_branch_person_role_id, person_id){
      // console.log('invite edildi');
      this.$database.AccountService.re_send_invite({
        CompanyBranchPersonRoleId: company_branch_person_role_id,
        PersonId: person_id
      }).then(res => {
        // console.log(res);
        if(res.IsSuccess === true || res.IsSuccess === 1){
          this.$functions.Messages.success('Invitation Sent')
        } else {
          this.$functions.Messages.error('Action failed!')
        }
      })
    }
  },
  created() {
    this.companyBranchID = this.$store.getters["auth/userInfo"].company_branch_id
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.getDistributorList();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/distributorList.scss" scoped></style>
